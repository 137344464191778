<template>
  <div
    class="pha-training px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Process Hazards Analysis (PHA) Training for Team Leaders"
      size="large"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <a
          class="heading-link mb-4"
          style="float:left;"
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2023%20PSM%20PHA%20Flyer.pdf?alt=media&token=https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2023%20PSM%20PHA%20Flyer.pdf?alt=media&token=87ce34ec-2a79-4171-81d8-bbe13f33aacb"
          target="_blank"
        >
          <div class="h-link">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="book"
              class="svg-inline--fa fa-book fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
              ></path>
            </svg>
            <h2
              class="content-box-heading fusion-responsive-typography-calculated"
              style="display:inline; font-size: 18px; line-height: 1.28; --fontSize:18; --minFontSize:18;"
              data-fontsize="18"
              data-lineheight="23.04px"
            >
              PHA Training Information Flyer
            </h2>
          </div></a
        >
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            This course consists of a combination of lecture and practical
            workshop exercises to allow participants to become familiar with the
            PSM requirements related to PHAs, selection of the appropriate PHA
            methodology(s), and how to effectively lead a PHA team. The unique
            aspects of propellant, explosives, and pyrotechnic (PEP)
            manufacturing/processing will be emphasized as PHA methodologies are
            discussed throughout the course. Participants will learn to organize
            and lead hazard analysis studies using the various PHA techniques.
            The course will address the tactics and success factors that help
            ensure a successful study. Participants will work in groups to apply
            PHA methodologies to example energetic material processes.
            Instructors will work closely with the participants throughout this
            session/workshop to ensure that key principles are understood. By
            applying the PHA methodologies to practical example processes, the
            participants will internalize the principles learned and gain added
            insight to the value of performing proper PHAs at their facilities.
          </p>
          <p>Course Content:</p>
          <ul>
            <li>Regulatory PHA Requirements</li>
            <li>Preparing and Organizing PHA Studies</li>
            <li>Subdividing the Process for Study</li>
            <li>Determining appropriate PHA methodology</li>
            <li>Leadership Skills for Managing the Team</li>
            <li>Applying Qualitative &amp; Quantitative PHA Methodologies</li>
            <li>Design Intent, Parameters and Deviations</li>
            <li>Human Factors</li>
            <li>Material Characterization Test Data</li>
            <li>How to document a PHA study</li>
            <li>PHA Report Preparation &amp; Approval process</li>
            <li>Managing the Follow-up of PHA Results</li>
          </ul>
        </Paragraph>
        <div class="flex flex-wrap mt-4">
          <div class="w-1/2 pt-4">
            <a
              href="http://www.tci-training.com"
              target="_blank"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Registration
            </a>
          </div>
          <div class="w-1/2">
            <a href="http://www.tci-training.com"
              ><img
                class="alignnone size-full wp-image-12060"
                src="@/assets/Training/tci-logo-min.jpg"
                alt="tci-logo"
                width="147"
                height="50"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Process Hazards Analysis Course",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "This course consists of a combination of lecture and practical workshop exercises to allow participants to become familiar with the PSM requirements related to PHAs, selection of the appropriate PHA methodology(s), and how to effectively lead a PHA team."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.pha-training {
  .h-link:hover {
    color: #aa222c;
    .fa-book {
      color: #aa222c;
    }
  }
  .fa-book {
    color: black;
    width: 18px;
    display: inline;
  }
}
</style>
